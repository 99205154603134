'use client'
import React, { useState } from 'react'
import UpCarrot from 'components/common/icons/UpCarrot'
import DownCarrot from 'components/common/icons/DownCarrot'

interface FAQSectionProps {
  header: string
  faq: { question: string; answer: string }[]
  className?: string
}

export default function FAQSection({
  header,
  faq,
  className,
}: FAQSectionProps) {
  function FAQItem({
    question,
    answer,
    startOpen = false,
    noTopBar = false,
  }: {
    question: string
    answer: string
    startOpen?: boolean
    noTopBar?: boolean
  }) {
    const [open, setOpen] = useState(startOpen)
    return (
      <>
        <div
          className="flex w-full cursor-pointer flex-col gap-10"
          onClick={() => setOpen((prevOpen) => !prevOpen)}
        >
          {!noTopBar && <hr className="h-px w-full border-0 bg-[#E0E0E0]" />}
          <div className="flex flex-row items-center justify-between gap-4">
            <h3 className=" text-[24px] font-normal ">{question}</h3>
            {open ? (
              <UpCarrot className="flex-shrink-0 flex-grow-0 " />
            ) : (
              <DownCarrot className="flex-shrink-0  flex-grow-0 " />
            )}
          </div>
          <div
            className={`duration-400 overflow-hidden text-[#767676] transition-all ${
              open ? 'mb-6 max-h-screen' : 'max-h-0'
            }`}
          >
            {answer}
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className={`flex flex-col items-start md:items-center ${className}`}>
        <h2 className="mb-6 text-[32px] font-normal md:mb-10 md:text-[40px]">
          {header}
        </h2>
        {faq.map(({ question, answer }, index) => (
          <FAQItem
            key={index}
            startOpen={index === 0}
            question={question}
            answer={answer}
            noTopBar={index === 0}
          />
        ))}
      </div>
    </>
  )
}
