import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/(home)/assets/giftOfChoice1.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/(home)/assets/giftOfChoice2.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/(home)/assets/giftOfChoice3.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/(home)/assets/instantlyShop.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/(home)/assets/openSomethingMagical.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/(home)/assets/personalizeIt.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/(home)/assets/pickThePerfectCard.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/(home)/assets/receiveNotification.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/(home)/assets/textOrEmail.webp");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/app/(home)/components/HeroBanner.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/components/Card/apollocardbgs/Blue.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/components/Card/apollocardbgs/Green.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/components/Card/apollocardbgs/Orange.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/components/Card/apollocardbgs/Pink.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/components/Card/apollocardbgs/Purple.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/components/Card/cardlogo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/common/FAQSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/common/header/HeaderBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/common/input/Button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/common/NewsletterSignup.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
