'use client'
import { useState } from 'react'
import Button from './input/Button'
import { sputnikPost } from 'shared/sputnik/client'

enum SubscribedState {
  NotClicked = 'NOT_CLICKED',
  Success = 'SUBSCRIBED_SUCCESS',
  Error = 'SUBSCRIPTION_ERROR',
}

export default function NewsletterSignup() {
  const [email, setEmail] = useState('')
  const [subscribed, setSubscribed] = useState<SubscribedState>(
    SubscribedState.NotClicked,
  )

  const subscribeToNewsletter = () => {
    if (!email || email == '') return
    sputnikPost('/v1/public/user/newsletter/subscribe', {
      email,
    })
      .then(() => {
        setSubscribed(SubscribedState.Success)
      })
      .catch(() => {
        setSubscribed(SubscribedState.Error)
      })
  }

  return (
    <div className="flex flex-col gap-2">
      <label
        className="text-base font-normal text-neutral-700"
        id="email-address-label"
      >
        Email address
      </label>
      <div className="flex flex-col items-start gap-4 md:flex-row md:items-center md:gap-4">
        <input
          type="text"
          id="email-input"
          aria-labelledby="email-address-label"
          name="Email address input"
          className="w-full rounded-[16px] border border-neutral-500 bg-white p-4 focus:outline-none md:w-[345px]"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          className={`max-md:w-full max-md:items-center ${subscribed === SubscribedState.Success && 'bg-neutral-500'}`}
          onClick={subscribeToNewsletter}
          primary
          disabled={subscribed === SubscribedState.Success}
        >
          <span className="max-md:mx-auto">Subscribe</span>
        </Button>
      </div>
      {subscribed === SubscribedState.Success && (
        <span className="text-md text-green-700">
          Thanks for subscribing to our newsletter! 💌
        </span>
      )}
      {subscribed === SubscribedState.Error && (
        <span className="text-md text-red-700">
          Invalid email, please try again.
        </span>
      )}
    </div>
  )
}
