import { SVGProps } from 'react'

export default function UpCarrot(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="10"
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.5 9L10.2071 1.70711C9.81658 1.31658 9.18342 1.31658 8.79289 1.70711L1.5 9"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
