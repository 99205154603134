'use client'
import React, { useState } from 'react'
import Image, { StaticImageData } from 'next/image'
import Button from 'components/common/input/Button'
import hero1 from '../assets/hero/hero1.webp'
import hero2 from '../assets/hero/hero2.webp'
import hero3 from '../assets/hero/hero3.webp'
import hero4 from '../assets/hero/hero4.webp'
import hero5 from '../assets/hero/hero5.webp'
import Container from 'components/common/Container'

const heroes: {
  image: {
    src: StaticImageData
  }
  gradient: string
}[] = [
  {
    image: {
      src: hero1,
    },
    gradient:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #E9F1F9 20%, #ECDDFF 80%, rgba(255, 255, 255, 0.00) 100%)',
  },
  {
    image: {
      src: hero2,
    },
    gradient:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #F4E9E7 20%, #EAD5F5 80%, rgba(255, 255, 255, 0.00) 100%)',
  },
  {
    image: {
      src: hero3,
    },
    gradient:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #E7F8EB 20%, #F1FFDA 80%, rgba(255, 255, 255, 0.00) 100%)',
  },
  {
    image: {
      src: hero4,
    },
    gradient:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #EAE9F9 20%, #D6EAFF 80%, rgba(255, 255, 255, 0.00) 100%)',
  },
  {
    image: {
      src: hero5,
    },
    gradient:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #F9EBEA 20%, #FFF5D6 80%, rgba(255, 255, 255, 0.00) 100%)',
  },
]

export default function HeroBanner({}: {}) {
  const [isLoaded, setIsLoaded] = useState(false)

  const DURATION = 15
  const OVERLAP_PERCENT = 3
  const DELAY = 3

  return (
    <>
      <div className="relative mb-[40px] w-full md:mb-[80px]">
        <style>
          {`@keyframes firstherofade {
            ${0}% { opacity:1}
            ${100 / heroes.length - OVERLAP_PERCENT}% { opacity:1}
            ${100 / heroes.length}% { opacity:0}
            ${100 - OVERLAP_PERCENT}% { opacity:0}
            ${100}% { opacity:1}
}`}
        </style>
        <style>
          {`@keyframes herofade {
  0% { opacity:0}
  ${OVERLAP_PERCENT}% { opacity:1}
  ${100 / heroes.length}% { opacity:1}
  ${OVERLAP_PERCENT + 100 / heroes.length}% { opacity:0}
}`}
        </style>

        {heroes.map((hero, index) => (
          <div
            key={index}
            style={{
              background: hero.gradient,
              opacity: index === 0 ? '1' : '0',
              ...(isLoaded
                ? {
                    animationDelay:
                      index === 0
                        ? `${DELAY + (DURATION * index) / heroes.length}s`
                        : `${DELAY - (DURATION * OVERLAP_PERCENT) / 100 + (DURATION * index) / heroes.length}s`,
                    animationName: index === 0 ? 'firstherofade' : 'herofade',
                    animationDuration: `${DURATION}s`,
                    animationTimingFunction: 'ease-in-out',
                    animationIterationCount: 'infinite',
                  }
                : {}),
            }}
            className="absolute left-0 top-0 -z-10 h-full w-full"
          ></div>
        ))}
        <Container className="flex flex-col">
          <div className="relative mb-[40px] flex flex-1 flex-col items-center justify-between md:flex-row-reverse md:gap-4">
            <div className="relative aspect-square w-[600px] max-w-full shrink">
              {heroes.map((hero, index) => (
                <Image
                  key={index}
                  src={hero.image.src}
                  alt={'Shop at any of these brands with an On Me gift card'}
                  className={`absolute aspect-square w-full rounded-2xl object-cover`}
                  style={{
                    display: index === 0 || isLoaded ? 'block' : 'none',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    opacity: index === 0 ? '1' : '0',
                    ...(isLoaded
                      ? {
                          animationDelay:
                            index === 0
                              ? `${DELAY + (DURATION * index) / heroes.length}s`
                              : `${DELAY - (DURATION * OVERLAP_PERCENT) / 100 + (DURATION * index) / heroes.length}s`,
                          animationName:
                            index === 0 ? 'firstherofade' : 'herofade',
                          animationDuration: `${DURATION}s`,
                          animationTimingFunction: 'ease-in-out',
                          animationIterationCount: 'infinite',
                        }
                      : {}),
                  }}
                  sizes={
                    '(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 600px'
                  }
                  priority={index === 0}
                  onLoad={() => {
                    if (index === 0) {
                      setIsLoaded(true)
                    }
                  }}
                ></Image>
              ))}
            </div>
            <div className="flex basis-[200px] flex-col items-start justify-center gap-[24px] md:basis-[400px] md:gap-10 xl:basis-[450px] 2xl:basis-[600px]">
              <h1 className="text-[40px] leading-[48px] md:text-[56px] md:leading-[72px]">
                The perfect gift card for every hobby
              </h1>
              <span>
                So you can show up for every meaningful occasion, milestone, and
                moment.
              </span>

              <Button href={`/shop-gift-cards`} primary>
                View all gift cards
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}
